import get from 'lodash/get'
import getFormattedDateTime from '@helpers/get-formatted-date-time'
import * as dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'

dayjs.extend(utc)
dayjs.extend(isBetween)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

const fetchFilteredStories = (props, stories, searchValue) => {
  const { firmType, capabilities, products, valueDriver, featuredLocale } =
    props

  const broadensResults = 'broadensResults'
  const narrowsResults = 'narrowsResults'
  const alphabetical = 'alphabetical'
  const chronological = 'chronological'
  const reverseChronological = 'reverse-chronological'
  const webinarPastFuture = 'webinarPastFuture'
  const webinarCategory = 'webinarCategory'
  const segment = 'segment'
  const workflows = 'workflow'
  const onDemand = 'On-Demand'
  const product = 'product'
  const type = 'type'
  const dateRangePicker = 'dateRangePicker'
  const groupingSelect = 'groupingSelect'

  let sortedResources
  let searchFilteredResources
  const shouldSortByReverseChronologicalOrder =
    !props.sortBy || props.sortBy === reverseChronological

  const filterLogicRules = props.filterLogicRules

  const filterNarrowsResults =
    filterLogicRules &&
    filterLogicRules.filter(
      (filter) => filter.filterResultLogic === narrowsResults
    )

  const filterBroadensResults =
    filterLogicRules &&
    filterLogicRules.filter(
      (filter) => filter.filterResultLogic === broadensResults
    )

  const webinarsPastFutureFilter =
    typeof props.webinarPastFuture === 'object'
      ? get(props.webinarPastFuture, '[0]')
      : props.webinarPastFuture

  const webinarCategoriesFilter = props.webinarCategory
  const webinarSegmentFilter = props.segment
  const webinarWorkflow = props.workflow
  const webinarProductsFilter = props.product
  const eventsTypeFilter = props.type
  const dateRangePickerFilter = props.dateRangePicker
  const groupingSelectFilter =
    typeof props.groupingSelect === 'object'
      ? get(props.groupingSelect, '[0]')
      : props.groupingSelect

  const areSortedResources =
    props.sortBy === alphabetical ||
    props.sortBy === chronological ||
    shouldSortByReverseChronologicalOrder

  let allMatched = false
  let matchedResources = false
  let pushResources = false

  const getFormattedDate = (date) => dayjs(date).format('L')

  const fetchMultipleValueLogic = (value) => {
    const multiValueLogic = filterLogicRules.some(
      (multiValueLogic) =>
        multiValueLogic.filterDataPath === value &&
        multiValueLogic.multiValueLogic === narrowsResults
    )
    return multiValueLogic ? narrowsResults : broadensResults
  }

  const compareNarrowsResources = (
    filterOptions,
    storyOptions,
    filteredBroadensArray,
    multipleValuesLogic,
    story
  ) => {
    return filterOptions.forEach((filterOption, index) =>
      storyOptions &&
      storyOptions.length > 0 &&
      storyOptions.indexOf(filterOption) !== -1
        ? filterOptions.length - 1 === index &&
          !allMatched &&
          !pushResources &&
          story
          ? (filteredBroadensArray.push(story), (allMatched = true))
          : (matchedResources = true)
        : (pushResources = true)
    )
  }

  const compareBroadensResources = (
    filterOptions,
    storyOptions,
    filteredBroadensArray,
    multipleValuesLogic,
    story
  ) => {
    return (
      !!filterOptions &&
      filterOptions.forEach((filterOption) =>
        storyOptions &&
        storyOptions.length > 0 &&
        storyOptions.indexOf(filterOption) > -1 &&
        !matchedResources &&
        !allMatched &&
        story
          ? (filteredBroadensArray.push(story), (matchedResources = true))
          : storyOptions &&
            storyOptions.length > 0 &&
            storyOptions.indexOf(filterOption) > -1 &&
            !matchedResources &&
            (matchedResources = true)
      )
    )
  }

  const filterMatchedResources = (
    filterResult,
    category,
    filterDataPath,
    storyOptions,
    filteredArray,
    story
  ) => {
    const hasMatched = hasFilterOptionMatched(filterResult, category)
    if (hasMatched) {
      const multipleValues = fetchMultipleValueLogic(category)
      fetchListOfMatchedResources(
        filterDataPath,
        storyOptions,
        filteredArray,
        multipleValues,
        story
      )
    }
  }

  const fetchListOfMatchedResources = (
    filterOptions,
    storyOptions,
    filteredBroadensArray,
    multipleValuesLogic,
    story
  ) => {
    return multipleValuesLogic === narrowsResults
      ? compareNarrowsResources(
          filterOptions,
          storyOptions,
          filteredBroadensArray,
          multipleValuesLogic,
          story
        )
      : compareBroadensResources(
          filterOptions,
          storyOptions,
          filteredBroadensArray,
          multipleValuesLogic,
          story
        )
  }

  const hasFilterOptionMatched = (filterResults, option) =>
    filterResults.some((filterResult) => filterResult.filterDataPath === option)

  const loadFilteredResources = (resources) => {
    let filteredBroadensArray = []
    filterBroadensResults &&
      filterBroadensResults.length > 0 &&
      resources.forEach((story) => {
        if (webinarCategoriesFilter && webinarCategoriesFilter.length > 0) {
          filterMatchedResources(
            filterBroadensResults,
            webinarCategory,
            webinarCategoriesFilter,
            story.webinarCategory,
            filteredBroadensArray,
            story
          )
          pushResources = false
        }
        if (webinarsPastFutureFilter) {
          hasFilterOptionMatched(filterBroadensResults, webinarPastFuture) &&
            (webinarsPastFutureFilter === onDemand
              ? story.webinarVideo &&
                story.webinarVideo.length > 0 &&
                !allMatched &&
                filteredBroadensArray(story)((allMatched = true))
              : story.webinarVideo &&
                story.webinarVideo.length === 0 &&
                !allMatched &&
                filteredBroadensArray(story)((allMatched = true)))
        }
        if (webinarSegmentFilter && webinarSegmentFilter.length > 0) {
          filterMatchedResources(
            filterBroadensResults,
            segment,
            webinarSegmentFilter,
            story.segment,
            filteredBroadensArray,
            story
          )
          pushResources = false
        }
        if (webinarWorkflow && webinarWorkflow.length > 0) {
          filterMatchedResources(
            filterBroadensResults,
            workflows,
            webinarWorkflow,
            story.workflow,
            filteredBroadensArray,
            story
          )
          pushResources = false
        }
        if (
          !!webinarProductsFilter ||
          (webinarProductsFilter && webinarProductsFilter.length > 0)
        ) {
          filterMatchedResources(
            filterBroadensResults,
            product,
            typeof webinarProductsFilter === 'object'
              ? webinarProductsFilter
              : [webinarProductsFilter],
            story.product,
            filteredBroadensArray,
            story
          )
          pushResources = false
        }
        if (eventsTypeFilter) {
          filterMatchedResources(
            filterBroadensResults,
            type,
            [eventsTypeFilter],
            story.type,
            filteredBroadensArray,
            story
          )
          pushResources = false
        }
        if (dateRangePickerFilter) {
          const startDate = getFormattedDateTime(story.startDate, 'L')
          const endDate = getFormattedDateTime(story.endDate, 'L')
          const eventStartDate = getFormattedDate(
            dateRangePickerFilter.startDate
          )

          const eventendDate = getFormattedDate(dateRangePickerFilter.endDate)

          hasFilterOptionMatched(filterBroadensResults, dateRangePicker) &&
            ((dayjs(startDate).isSameOrAfter(eventStartDate) &&
              dayjs(endDate).isSameOrBefore(eventendDate)) ||
              (dayjs(startDate).isSameOrBefore(eventStartDate) &&
                dayjs(endDate).isSameOrAfter(eventendDate)) ||
              dayjs(endDate).isBetween(eventStartDate, eventendDate) ||
              dayjs(eventendDate).isBetween(startDate, endDate) ||
              dayjs(eventStartDate).isSame(dayjs(endDate)) ||
              dayjs(eventendDate).isSame(dayjs(startDate))) &&
            filteredBroadensArray.push(story)
          allMatched = true
        }
        if (
          groupingSelectFilter &&
          hasFilterOptionMatched(filterBroadensResults, groupingSelect) &&
          story.state === groupingSelectFilter
        ) {
          filteredBroadensArray.push(story)
          allMatched = true
        }
        matchedResources = false
        allMatched = false
      })

    let filteredNarrowsArray = []
    const filterNarrowsResources =
      filterBroadensResults && filterBroadensResults.length > 0
        ? filteredBroadensArray
        : stories
    filterNarrowsResults &&
      filterNarrowsResults.length > 0 &&
      filterNarrowsResources &&
      filterNarrowsResources.forEach((story) => {
        if (webinarCategoriesFilter && webinarCategoriesFilter.length > 0) {
          filterMatchedResources(
            filterNarrowsResults,
            webinarCategory,
            webinarCategoriesFilter,
            story.webinarCategory,
            filteredNarrowsArray
          )
        }
        if (webinarsPastFutureFilter) {
          hasFilterOptionMatched(filterNarrowsResults, webinarPastFuture) &&
            (webinarsPastFutureFilter === onDemand
              ? story.webinarVideo &&
                story.webinarVideo.length > 0 &&
                (matchedResources = true)
              : story.webinarVideo &&
                story.webinarVideo.length === 0 &&
                (matchedResources = true))
        }
        if (webinarSegmentFilter && webinarSegmentFilter.length > 0) {
          filterMatchedResources(
            filterNarrowsResults,
            segment,
            webinarSegmentFilter,
            story.segment,
            filteredNarrowsArray
          )
        }
        if (webinarWorkflow && webinarWorkflow.length > 0) {
          filterMatchedResources(
            filterNarrowsResults,
            workflows,
            webinarWorkflow,
            story.workflow,
            filteredNarrowsArray
          )
        }
        if (
          !!webinarProductsFilter ||
          (webinarProductsFilter && webinarProductsFilter.length > 0)
        ) {
          filterMatchedResources(
            filterNarrowsResults,
            product,
            typeof webinarProductsFilter === 'object'
              ? webinarProductsFilter
              : [webinarProductsFilter],
            story.product,
            filteredNarrowsArray
          )
        }

        if (eventsTypeFilter) {
          filterMatchedResources(
            filterNarrowsResults,
            type,
            [eventsTypeFilter],
            story.type,
            filteredNarrowsArray
          )
        }
        if (dateRangePickerFilter) {
          const startDate = new Date(getFormattedDateTime(story.startDate, 'L'))
          const endDate = new Date(getFormattedDateTime(story.endDate, 'L'))
          const eventStartDate = new Date(
            getFormattedDateTime(dateRangePickerFilter.startDate, 'L')
          )
          const eventendDate = new Date(
            getFormattedDateTime(dateRangePickerFilter.endDate, 'L')
          )

          hasFilterOptionMatched(filterBroadensResults, dateRangePicker) &&
            ((dayjs(startDate) >= dayjs(eventStartDate) &&
              dayjs(endDate) <= dayjs(eventendDate)) ||
              (dayjs(startDate) <= dayjs(eventStartDate) &&
                dayjs(endDate) >= dayjs(eventendDate))) &&
            (matchedResources = true)
        }
        if (groupingSelectFilter) {
          hasFilterOptionMatched(filterBroadensResults, groupingSelect) &&
            story.state === groupingSelectFilter &&
            (matchedResources = true)
        }
        matchedResources && filteredNarrowsArray.push(story)
        matchedResources = false
      })

    sortedResources =
      filterNarrowsResults.length > 0 || filterBroadensResults.length > 0
        ? filterNarrowsResults.length > 0
          ? filteredNarrowsArray.length > 0 && areSortedResources
            ? filteredNarrowsArray.sort(compare)
            : filteredNarrowsArray
          : areSortedResources
          ? filteredBroadensArray.sort(compare)
          : filteredBroadensArray
        : stories.sort(compare)
  }


  const compare = (firstObj, secondObj) => {
    firstObj = get(
      stories.filter(
        (filterResource) => filterResource.title === firstObj.title
      ),
      '[0]'
    )
    secondObj = get(
      stories.filter(
        (filterResource) => filterResource.title === secondObj.title
      ),
      '[0]'
    )

    if (firstObj && secondObj) {
      let comparison = 0
      if (props.sortBy === 'none') {
        comparison = 0
      } else {
        const nameA =
          props.sortBy === alphabetical
            ? firstObj.name
            : props.sortBy === chronological ||
              shouldSortByReverseChronologicalOrder
            ? firstObj.first_published_at || firstObj.created_at
            : null
        const nameB =
          props.sortBy === alphabetical
            ? secondObj.name
            : props.sortBy === chronological ||
              shouldSortByReverseChronologicalOrder
            ? secondObj.first_published_at || secondObj.created_at
            : null
        if (nameA > nameB) comparison = 1
        else if (nameA < nameB) comparison = -1
      }
      return comparison
    }
  }

  if (searchValue) {
    searchFilteredResources =
      stories &&
      stories.filter(
        (data) =>
          data.title &&
          data.title.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
      )
  } else if (filterLogicRules.length > 0) {
    loadFilteredResources(stories)
  }

  return searchValue
    ? searchFilteredResources
    : shouldSortByReverseChronologicalOrder
    ? sortedResources.reverse()
    : sortedResources
}

export default fetchFilteredStories
