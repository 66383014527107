import React, { useState, useContext, useEffect, useRef } from 'react'
import renderBlok from '@renderBlok'
import SbEditable from 'storyblok-react'
import { Box } from '@material-ui/core'
import { EventsCard } from '@system'
import { makeStyles } from '@material-ui/styles'
import fetchFilteredStories from '@helpers/fetch-filtered-stories'
import useStickyFilter from '@hooks/use-sticky-filter'
import PageContext from '@context'
import isInBrowser from '@helpers/is-in-browser'
import get from 'lodash/get'
import classNames from 'classnames'
import { getCurrentDateAndTimeInUTC, getFormattedDateTime } from '@helpers'
import * as dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'

dayjs.extend(utc)
dayjs.extend(isSameOrAfter)

const useStyles = makeStyles((theme) => ({
  // Filter
  filterBarOuterContainer: {
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
      maxWidth: '90%',
    },
    [theme.breakpoints.up('md')]: {
      position: 'sticky',
      zIndex: 1100,
      '&:before': {
        background: theme.palette.background.paper,
        boxShadow:
          '0px 2px 2px -1px rgba(0,0,0,0.2), 0px 4px 3px 0px rgba(0,0,0,0.14), 0px 1px 1px 0px rgba(0,0,0,0.12)',
        content: '""',
        height: '100%',
        left: '0',
        marginLeft: '50%',
        position: 'absolute',
        opacity: '0',
        top: '0',
        transform: 'translateX(-50%)',
        transition: '0.2s opacity, 0.2s visibility',
        visibility: 'hidden',
        width: 'var(--filter-bar-width)',
        zIndex: '-1',
      },
    },
  },
  filterBarOuterContainerSticky: {
    [theme.breakpoints.up('md')]: {
      '&:before': {
        opacity: '1',
        visibility: 'visible',
      },
    },
  },
  filterBarInnerContainer: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '1056px',
      margin: '0 auto',
      padding: '0 49.6px',
    },
  },
  // Events
  eventsCards: {
    margin: '0 auto',
  },
  eventsCard: {
    margin: '40px auto',
  },
}))

const EventsFilter = (props) => {
  const classes = useStyles()
  const pageContext = useContext(PageContext)
  const stickyContainerRef = useRef()
  const isSticky = useStickyFilter(stickyContainerRef.current)

  const { filtersMenu } = props.blok
  const [searchValue, setSearchValue] = useState(null)

  const [filters, setFilters] = useState([
    {
      filterBy: '',
      filterSelect: '',
    },
  ])
  const [filterLogicRules, setFilterLogicRules] = useState([
    {
      filterResultLogic: '',
      multiValueLogic: '',
      filterDataPath: '',
    },
  ])

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ])

  const broadensResults = 'broadensResults'
  const narrowsResults = 'narrowsResults'
  let eventsFilteredStories

  const staticEventFilters = [
    {
      filterType: 'dateRangePicker',
      filterDataPath: 'dateRangePicker',
      filterResultsLogic: 'broadensResults',
      multipleValuesLogic: 'broadensResults',
    },
    {
      filterType: 'groupingSelect',
      filterDataPath: 'groupingSelect',
      filterResultsLogic: 'broadensResults',
      multipleValuesLogic: 'broadensResults',
    },
  ]

  const filteredMenuList = get(props.blok.filtersMenu, '[0].filtersList')

  const currentDateAndTimeInUTC = getCurrentDateAndTimeInUTC()
  const eventsStories =
    pageContext.events &&
    pageContext.events
      .filter((event) =>
        dayjs(
          getFormattedDateTime(event.content.endDate, 'YYYY-MM-DD HH:mm')
        ).isSameOrAfter(
          getFormattedDateTime(currentDateAndTimeInUTC, 'YYYY-MM-DD HH:mm')
        )
      )
      .map((event) => event.content)

  const formattedEventsStories = {
    type: [],
    dateRangePicker: {},
    sortBy: '',
    groupingSelect: '',
  }

  const filterByParam = []
  const filterSelectParam = []

  if (isInBrowser) {
    const href = pageContext.location
      ? pageContext.location.href
      : document.location.href
    const paramsString = new URL(href)

    for (let keys of paramsString.searchParams.keys()) {
      let isFilteredParams =
        filteredMenuList.some((params) => params.filterDataPath === keys) ||
        staticEventFilters.some((params) => params.filterDataPath === keys)

      if (keys !== 'path' && keys.indexOf('_storyblok') < 0) {
        if (keys === 'all') {
          filterByParam.push({
            filterBy: 'sortBy',
            filterSelect: 'reverse-chronological',
          })
          filterSelectParam.push({
            filterResultLogic: '',
            multiValueLogic: '',
            filterDataPath: '',
          })
        } else if (isFilteredParams) {
          let filterLogicRules = filteredMenuList.filter(
            (filterList) => filterList.filterDataPath === keys
          )
          let filterResultLogic = get(
            filterLogicRules,
            '[0].filterResultsLogic'
          )
          let multipleValueLogic = get(
            filterLogicRules,
            '[0].multipleValuesLogic'
          )

          if (keys === 'dateRangePicker') {
            filterByParam.push({
              filterBy: keys,
              filterSelect: JSON.parse(
                paramsString.searchParams.getAll(keys)[0]
              ),
            })
          } else {
            filterByParam.push({
              filterBy: keys,
              filterSelect: paramsString.searchParams
                .getAll(keys)[0]
                .split(','),
            })
          }

          if (filterResultLogic === undefined) {
            filterResultLogic = broadensResults
          }
          if (multipleValueLogic === undefined) {
            multipleValueLogic = narrowsResults
          }
          filterSelectParam.push({
            filterResultLogic: filterResultLogic,
            multiValueLogic: multipleValueLogic,
            filterDataPath: keys,
          })
        }
      }
    }
  }

  useEffect(() => {
    setFilters(filterByParam)
    setFilterLogicRules(filterSelectParam)
  }, [])

  const updateFilterLogicRules = (filterDataPath) =>
    filterLogicRules.filter(
      (filter) => filterDataPath.indexOf(filter.filterDataPath) !== -1
    )

  useEffect(() => {
    const filterDataPath = filters.map((filter) => filter.filterBy)
    if (!searchValue) {
      if (isFilterSelected) {
        if (isInBrowser) {
          const href = pageContext.location
            ? pageContext.location.origin + pageContext.location.pathname
            : document.location.href
          const paramsString = new URL(href)
          const filterParam = filters.filter(
            (filter) =>
              filter.filterSelect.length > 0 || filter.filterSelect.startDate
          )

          filterParam.forEach((param) => {
            if (param.filterBy !== '') {
              if (param.filterBy === 'dateRangePicker') {
                paramsString.searchParams.append(
                  param.filterBy,
                  JSON.stringify(param.filterSelect)
                )
              } else {
                paramsString.searchParams.append(
                  param.filterBy,
                  param.filterSelect
                )
              }
            }
          })
          window.history.pushState({}, '', paramsString.href)
        }
      } else {
        window.history.pushState(
          {},
          '',
          pageContext.path || document.location.href
        )
      }
    }
    setFilterLogicRules(updateFilterLogicRules(filterDataPath))
  }, [filters])

  const isFilterSelected = filters.some(
    (eachFilter) =>
      (eachFilter.filterSelect && eachFilter.filterSelect.length > 0) ||
      eachFilter.filterSelect.startDate
  )

  const onChangeDateRange = (event) => {
    setDateRange([event.selection])
  }

  const onChangeFilters = (event, filterName) => {
    const emptyString = ''
    const value =
      filterName === 'dateRangePicker'
        ? event
        : event.target.value || emptyString

    setSearchValue(null)
    if (value === emptyString) {
      setFilters((oldFilters) =>
        oldFilters.filter((item) => item.filterBy !== filterName)
      )
      setFilterLogicRules((oldFilterLogicRules) =>
        oldFilterLogicRules.filter(
          (filterResultLogic) => filterResultLogic.filterDataPath !== filterName
        )
      )
    } else {
      setFilters((oldFilters) => {
        const matchFilters = oldFilters.some(
          (filter) => filter.filterBy === filterName
        )
        return matchFilters
          ? oldFilters.map((filter) => {
              if (filter.filterBy === filterName) filter.filterSelect = value
              return filter
            })
          : [...oldFilters, { filterBy: filterName, filterSelect: value }]
      })
    }
  }

  const onChangeSearchText = (event) => {
    const SearchTextValue = event.target.value
    setSearchValue(SearchTextValue)
    setFilters([
      {
        filterBy: '',
        filterSelect: '',
      },
    ])
    setFilterLogicRules([
      { filterResultLogic: '', multiValueLogic: '', filterDataPath: '' },
    ])
  }

  const resetFilters = () => {
    window.history.pushState({}, '', pageContext.path || document.location.href)
    setSearchValue(null)
    setFilters([
      {
        filterBy: '',
        filterSelect: '',
      },
    ])
    setFilterLogicRules([
      { filterResultLogic: '', multiValueLogic: '', filterDataPath: '' },
    ])
    setDateRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      },
    ])
  }

  const fetchFilterLogicRules = (
    filterResultLogic,
    multiValueLogic,
    filterDataPath
  ) => {
    setSearchValue(null)
    setFilterLogicRules((oldFilterLogicRules) => {
      const haveOldFiltersMatched = oldFilterLogicRules.some(
        (filterResultLogic) =>
          filterResultLogic.filterDataPath === filterDataPath
      )
      return haveOldFiltersMatched
        ? [...oldFilterLogicRules]
        : [
            ...oldFilterLogicRules,
            {
              filterResultLogic: filterResultLogic,
              multiValueLogic: multiValueLogic,
              filterDataPath: filterDataPath,
            },
          ]
    })
  }

  filters.forEach((filter) => {
    return (formattedEventsStories[`${filter.filterBy}`] = filter.filterSelect)
  })

  const filterLogic = filterLogicRules.some(
    (filter) => filter.filterDataPath !== ''
  )
    ? filterLogicRules
    : filterSelectParam

  if (isFilterSelected || !!searchValue) {
    eventsFilteredStories = fetchFilteredStories(
      { ...formattedEventsStories, filterLogicRules: filterLogic },
      eventsStories,
      ...searchValue
    )
  } else {
    eventsFilteredStories = null
  }

  let filteredEventsStories
  if (isFilterSelected || !!searchValue) {
    if (eventsFilteredStories.length) {
      filteredEventsStories = eventsFilteredStories
    } else {
      filteredEventsStories = props.noResultsCopy
    }
  } else if (eventsStories.length) {
    filteredEventsStories = eventsStories
  } else {
    filteredEventsStories = props.noEventsCopy
  }

  return (
    <SbEditable content={props.blok}>
      <Box
        className={classNames(classes.filterBarOuterContainer, {
          [classes.filterBarOuterContainerSticky]: isSticky,
        })}
        ref={stickyContainerRef}
      >
        {!!filtersMenu &&
          filtersMenu.map((filterMenu) => (
            <Box
              key={filterMenu._uid}
              className={classes.filterBarInnerContainer}
            >
              {renderBlok({
                ...filterMenu,
                filters,
                staticEventFilters,
                onChangeSearchText,
                onChangeFilters,
                fetchFilterLogicRules,
                searchValue,
                resetFilters,
                isEventModule: props.isEventsModule,
                dateRange,
                onChangeDateRange,
              })}
            </Box>
          ))}
      </Box>
      <Box container="true" className={classes.eventsCards}>
        <Box className={classes.eventsCard}>
          <EventsCard stories={filteredEventsStories} />
        </Box>
      </Box>
    </SbEditable>
  )
}

export default EventsFilter
