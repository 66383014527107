import { useEffect, useState } from 'react'

function useStickyFilter(filterBarRef) {
  const [filterBarTop, setFilterBarTop] = useState(null)
  const [filterBarWidth, setFilterBarWidth] = useState(null)
  const [isSticky, setIsSticky] = useState(false)

  function handleStickyObserver() {
    const intersectionObserver = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsSticky(false)
          return
        }

        if (entry.boundingClientRect.top <= filterBarTop + 1) {
          handleStickyWidth()
          setIsSticky(true)
        }
      },
      {
        rootMargin: filterBarTop ? `-${filterBarTop + 1}px 0px 0px 0px` : '0px',
        threshold: 1.0,
      }
    )

    intersectionObserver.observe(filterBarRef)
  }

  function handleStickyTop() {
    if (typeof document !== 'undefined') {
      const headerHeight = document
        .getElementById('topNavBar')
        ?.getBoundingClientRect().height

      setFilterBarTop(headerHeight)
    }
  }

  function handleStickyWidth() {
    if (typeof document !== 'undefined') {
      const bodyWidth = document.body.clientWidth

      setFilterBarWidth(bodyWidth)
    }
  }

  useEffect(() => {
    handleStickyTop()
  }, [])

  useEffect(() => {
    if (filterBarRef && filterBarTop) {
      filterBarRef.style.top = `${filterBarTop}px`
    }

    if (filterBarRef && filterBarWidth) {
      filterBarRef.style.setProperty(
        '--filter-bar-width',
        `${filterBarWidth}px`
      )
    }
  }, [filterBarRef, filterBarTop, filterBarWidth])

  useEffect(() => {
    if (filterBarRef) {
      handleStickyObserver(filterBarRef)
    }
  }, [filterBarRef])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleStickyWidth)
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', handleStickyWidth)
      }
    }
  }, [])

  return isSticky
}

export default useStickyFilter
